const parseBase64Url = (token) => {

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

const parseUsername = (token) => {
    let name = '';
    let role = '';
    let stamp = 'parse';
  
    if(token){
        const parsed = parseBase64Url(token);
        if (parsed) {
            if (parsed.username) {
                name = parsed.username.charAt(0).toUpperCase() + parsed.username.slice(1);
            }               
            if (parsed.role) {
                role = parsed.role;
            }                   
            if (parsed.stamp) {
                stamp = parsed.stamp;
            }                   
        }                   
    }
    return {name: name, role: role, stamp: stamp}
}

export const auth = {
    parseUsername: parseUsername
}
