import React from 'react';
import './App.scss';
import './adportal.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Redirect, Switch, Route} from "react-router-dom";
import SitesModel from "./components/stores/sites/sitesModel";
import Placements from "./components/stores/placement/placements";
import SiteList from "./components/containers/sitelist/sitelist";
import ProtectedRoute from "./components/protectedroute";
import Login from "./components/login/login";
import Auth from "./components/stores/auth/auth";
import Sizes from "./components/stores/sizes/sizes";
import Network from "./components/stores/network/network";
import {Adstxt} from "./components/ads-txt/ads-txt";
import Header from "./components/header/header";
import Timeout from "./components/timeout/timeout";
import AllerToast from "./components/allertoast/allertoast";
import ErrorBoundary from "./components/ErrorBoundary";
import Options from "./components/containers/options/options";
import VerticalsModel from "./components/stores/verticals/verticalsModel";
import { AuthBasedRender } from "./components/stores/auth/authbasedrender";
import { AuthRoles } from "./components/stores/auth/authRole";
import BundleVersionContextModel from './components/stores/adsBundleVersion/bundleVersionModel';

function App() {
  return (
    <Router>
        <div className="App">
        <ErrorBoundary>
        <Auth>
              <Network>
              <AllerToast>
              <Sizes>
                  <VerticalsModel>
                  <BundleVersionContextModel>
            <Placements>
                      <SitesModel>


            <div className={"app-wrapper"}>
                <Switch>
                  <Route exact path="/">
                        <Redirect to="/settings" />
                    </Route>
                    <Route path="/login">
                        <Login/>
                    </Route>
                        <>
                            <Timeout />
                            <Header />
                            <ProtectedRoute path="/settings">
                                <SiteList/>
                            </ProtectedRoute>

                            <ProtectedRoute path="/ADS">
                                <AuthBasedRender role={AuthRoles.Admin}>
                                    <Adstxt/>
                                </AuthBasedRender>
                            </ProtectedRoute>

                            <ProtectedRoute path="/options">
                                <Options/>
                            </ProtectedRoute>
                        </>


                            <ProtectedRoute path="/options">
                                <Options/>
                            </ProtectedRoute>

                  </Switch>
            </div>

                      </SitesModel>
                  </Placements>
                  </BundleVersionContextModel>
                  </VerticalsModel>
              </Sizes>
              </AllerToast>
              </Network>
          </Auth>
          </ErrorBoundary>
          </div>
    </Router>
  );
}

export default App;

