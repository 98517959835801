import React, { useReducer, useEffect, useState } from "react";
import { USER_AUTHENTICATED, USER_LOGGEDOUT } from "./types";
import AuthContext from "./authcontext";
import AuthReducer from "./authreducer";
import cookie from 'react-cookies'
import {requests} from "../../../helpers/requests";
import axios from 'axios';
import instance from "../../../axios";
import isUndefined from "lodash/isUndefined";
import {useLocalStorage} from "../../../hooks/uselocalstorage";
import { auth } from '../../../helpers/auth';


const Auth = (props) => {

    const [name, setName] = useLocalStorage('name', '')
    const [role, setRole] = useLocalStorage('role', '')
    const [stamp, setStamp] = useLocalStorage('stamp', '');

    let initialState = {
            token: cookie.load('token'),
            name: name,
            stamp: stamp,
            role: role
        };

    const [state, dispatch] = useReducer(AuthReducer, initialState);
    const [request, setRequest] = useState();

    useEffect( () => {

        setName(state.name)
        setRole(state.role)
        setStamp(state.stamp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        const source = axios.CancelToken.source();
        const { config } = requests;

        if (requests.isValid(request)) {

            instance({ ...request, config, cancelToken: source.token, withCredentials: true}, config)
            .then( response => {

                let username = ''
                let userrole = ''
                let loginstamp = ''

                if (response.data && response.data.token) {
                    const { name = '', role = '', stamp = ''} = auth.parseUsername(response.data.token);
                    username = name
                    userrole = role
                    loginstamp = stamp
                }

                dispatch({ type: request.completeAction, payload: {...response.data, name: username, role: userrole, stamp: loginstamp}});
            })
            .catch(err => {
    
                console.log("Catched error: " + err.message);
            })
        }
        return () => {
            source.cancel("Request cancelled");
        };    
    }, [request]);

            
    useEffect(() => {
    
        if(isUndefined(state.token)){
            cookie.remove('token');
        }

    }, [state.token])

    const login = (username, password) => {

        setRequest(
            {
                method: 'POST',
                url: 'user/login',
                data: {username, password},
                completeAction: USER_AUTHENTICATED
            })
    }
    

    const logout = () => setRequest({method: 'POST', url: 'user/logout', completeAction: USER_LOGGEDOUT})

    return (
        <AuthContext.Provider value={
            {
                token: state.token,
                name: state.name,
                role: state.role,
                stamp: state.stamp,
                login,
                logout
            }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default Auth;