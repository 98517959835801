import {USER_AUTHENTICATED,USER_LOGGEDOUT} from "./types";

export default (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case USER_AUTHENTICATED:            
            return {...state, ...payload}
        case USER_LOGGEDOUT:
            return {...state, token: undefined, name: '', role: '', stamp: ''}
        default:
            return state;
    }
};
