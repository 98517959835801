import React, { useState, useContext, useCallback, useEffect, useRef, useMemo} from "react";
import styles from './addsite.module.scss'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../hooks/useaxios';
import sitesContext from "../stores/sites/sitescontext";
import { FormControl } from "react-bootstrap";
import {
    find,
    propEq,
    pipe,
    isEmpty,
    is,
    pathOr,
    complement,
    equals
} from 'ramda';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import {useOnClickOutside} from "../../hooks/useonclickoutside";

const valid = pathOr(undefined, ['current', 'validity', 'valid']);

const isNotValid = pipe(valid, equals(false))

const refVal = pathOr(undefined, ['current', 'value']);

const hasValue = pipe(refVal, complement(isEmpty));




const AddSite = () => {

    const [sitename, setSitename] = useState(''); 
    const {data, loading} = useAxios();
    const SitesContext = useContext(sitesContext);

    const [displayError, setDisplayError] = useState(false);

    const ref = useRef();
    const [parentId, setParentId] = useState(0);

    useOnClickOutside(ref, () => setDisplayError(false));

    useEffect( () => {
        setDisplayError(false);
        setSitename('');

    }, [SitesContext.sites]);

    useEffect(() => {
        setSitename('');
    }, [data])

    // @TODO: This could be so much better
    const siteExists = useMemo(() => {
        const trg = find(propEq('gamSiteId', sitename), SitesContext.sites)
        return is(Object, trg);
    }, [sitename, SitesContext.sites]);


    const submitAllowed = !loading && !siteExists && valid(ref)

    const inputChange = useCallback(() => {
        setSitename(refVal(ref))
    }, [ref]);

    const onBlur = useCallback(() =>  setDisplayError(hasValue(ref)), [setDisplayError])

    const forceValid = useCallback(() => setDisplayError(false), [setDisplayError]);

    const onSubmit = useCallback((e) => {

        const allowed = valid(ref) && submitAllowed;
        if(allowed){
            SitesContext.createSite({
                                    domain: refVal(ref),
                                    siteId: parentId 
                })
        }

        e.preventDefault();

        return allowed;
    }, [submitAllowed, SitesContext, parentId]);


    const resolveMainDomain = useMemo(() => {

        const mainDomain = sitename.match(/([a-zA-Z-]+\.[a-zA-Z]+)$/);
        return mainDomain ? mainDomain.shift() : null;
    }
    , [sitename]);

    const findMatch = useCallback( (domain) => {

        const match = SitesContext.sites
        .find((site) => {
            return (site.gamSiteId === domain && site.parentSiteId === 0)
        })    
        return match ? match.siteId : 0;    

    }, [SitesContext.sites]);
    
    useEffect(() => {

        let parentId = 0;
        const domain = resolveMainDomain;

        if(domain){
            parentId = findMatch(domain);
        }
        setParentId(parentId);

    }, [SitesContext.sites, sitename, findMatch, resolveMainDomain]) 

    return (
        <>
            <Overlay target={ref.current} show={ (displayError && isNotValid(ref)) || (valid && siteExists)} placement="bottom">

                    <Tooltip className={'tooltip-error'}>
                        { isNotValid(ref) && <>Use the full domain name</>}
                        { siteExists && <>Site already exists</>}
                    </Tooltip>

            </Overlay>
            <Form inline noValidate onSubmit={onSubmit} className={styles.addSiteForm}>

                <FormControl onBlur={onBlur} onFocus={forceValid} ref={ref} onChange={inputChange} pattern="^([a-zA-Z-]+\.[a-zA-Z]+){1,2}$" className={`${styles.addsite_item}`} required type="text" value={sitename} placeholder="site.se" ></FormControl>

                <Button type='submit' className={styles.addSiteButton__Small}>Add Site</Button>
                
                { findMatch(resolveMainDomain) !== 0 && <Form.Label><i> Main domain: <b>{resolveMainDomain}</b></i></Form.Label>}                
            </Form>
        </>
    )
}

export default AddSite;
