import React, { useContext } from "react";
import Layout from "../../layout/layout";
import AddSite from '../../addsite/addsite';
import styles from './options.module.scss'
import { useAxios } from "../../../hooks/useaxios";
import { downloadFile, getFilenameFromContentDispositionHeader, isNotNil } from "../../../helpers/utils";
import AddVerticals from "../../addverticals/addVerticals";
import verticalsContext from "../../stores/verticals/verticalsContext";
import { AuthBasedRender } from "../../stores/auth/authbasedrender";
import { AuthRoles } from "../../stores/auth/authRole";

const Options = () => {

    const { setRequest } = useAxios();
    const VerticalsContext = useContext(verticalsContext);

    // If more stuff is added here this should be separated out a bit more

    const handleComplete = (response, data) => {
        const filename = getFilenameFromContentDispositionHeader(response.headers);
        if (isNotNil(filename)) {
            downloadFile(data, filename);
        }
    }
    const downloadClickHandler = () => {
        setRequest({
            method: 'GET',
            url: `config/downloadDb`,
            responseType: 'blob',
            onComplete: handleComplete

        });
    }

    return (
        <Layout>
            <div className={styles.option_wrapper}>
                <AuthBasedRender role={AuthRoles.Admin}>
                    <div className={styles.option}>
                        <h2>Add site</h2>
                        <p>Enter the name of the site to add (e.g. frida.se) and press the 'add site' button.</p>

                        <p>Enter "blog" as subdomain to get a blog site (e.g. blog.frida.se), that is default for all subdomains</p>
                        <p>For a specific subdomain-site add something else than "blog" (e.g. newsubdomain.frida.se).</p>

                        <AddSite />
                    </div>
                </AuthBasedRender>

                <div className={styles.option}>
                    <h2>Verticals</h2>
                    <p>Existing verticals visible in the list are not clickable and are only to be seen as a reference.</p>
                    <AddVerticals allowNew={true} onCallback={VerticalsContext.save} />
                </div>

                <AuthBasedRender role={AuthRoles.Admin}>
                    <div className={styles.option}>
                        <h2>Database</h2>
                        <p>Click on the download button below to download a database dump in SQL format.</p>
                        <button onClick={downloadClickHandler} disabled="" label="Save" type="submit" className="btn btn-primary">Download</button>
                    </div>
                </AuthBasedRender>
            </div>
        </Layout>

    )
}

export default Options;
